import { Injectable } from '@angular/core';
import { ICreateStudentGroupRequest, ILanguageLevelsEnum, IRegisterStudentToTeachingLanguageDto, IStudentBasicInfoDto, IStudentGroupDto, IStudentLevelEnum, ITeachingLanguageDto } from '@GeneratedTsFiles/index';
import { Severity } from '../models/severity';

export enum StoudentGroupRuleType {
  Error = 'error',
  Warning = 'warning',
  Info = 'info',
}

export interface StoudentGroupRuleMessage {
  type: Severity;
  message: string;
}

export interface StoudentGroupRule {
  type: Severity;
  message: string;
  validate: (existingGroups: IStudentGroupDto[], newGroup: any, isEditMode: boolean) => boolean;
}

@Injectable({
  providedIn: 'root'
})
export class StudentGroupService {

  currentGroupStudents: IStudentBasicInfoDto[] = []; // Students currently in the group
  studentsToAdd: string[] = []; // IDs of students to add
  studentsToRemove: string[] = []; // IDs of students to remove

  private studentGroupRequest: ICreateStudentGroupRequest = {} as ICreateStudentGroupRequest;


  private rules: StoudentGroupRule[] = [
    {
      type: Severity.Info,
      message: 'At least two students must be selected to create a group class.',
      validate: (existingGroups: IStudentGroupDto[], newGroup: Record<'groupLanguageName' | 'groupLevel' | 'studentsBasicInfo', any>) => newGroup.studentsBasicInfo.length < 2,
    },
    {
      type: Severity.Danger,
      message: 'A group with the same language and level already exists for the selected students.',
      validate: (existingGroups: IStudentGroupDto[], newGroup: Record<'groupLanguageName' | 'groupLevel' | 'studentsBasicInfo', any>) => this.groupExists(existingGroups, newGroup),
    },
    {
      type: Severity.Warning,
      message: '',
      validate: (existingGroups: IStudentGroupDto[], newGroup: Record<'groupLanguageName' | 'groupLevel' | 'studentsBasicInfo', any>, isEditMode: boolean) => {
        if (isEditMode) {
          // Get the IDs of students already in the group being edited
          // Create a set of existing student IDs in the current group
          const existingStudentIds = new Set(this.currentGroupStudents.map(student => student.id));

          // Filter to find new students being added (those not already in the current group)
          const newStudentsToAdd = newGroup.studentsBasicInfo.filter((student: IStudentBasicInfoDto) => !existingStudentIds.has(student.id));

          // Create a temporary object to match the IStudentGroupDto interface
          const tempStudentGroup: IStudentGroupDto = {
            id: '', // Provide a temporary ID if needed
            groupLanguageName: newGroup.groupLanguageName,
            groupLevel: newGroup.groupLevel,
            groupStatus: 1,
            studentsBasicInfo: newStudentsToAdd
          };

          // Check for overlapping students in the other groups
          const overlappingNames = this.studentsBelongToOtherGroupWithSameLanguage(existingGroups, tempStudentGroup);
          // Check for overlapping students in the other groups, excluding existing students
          const newStudentsToCheck = newGroup.studentsBasicInfo.filter((student: IStudentBasicInfoDto) => !existingStudentIds.has(student.id));
          console.error(this.currentGroupStudents)
          console.error(newStudentsToCheck)
          if (overlappingNames.length > 0) {
            this.rules[2].message = `Some selected students (${overlappingNames.join(', ')}) are already in another group for the same language.`;
            return true; // Warning condition met
          }
          return false; // No overlapping students
        } else {
          // Original logic for new groups
          const overlappingNames = this.studentsBelongToOtherGroupWithSameLanguage(existingGroups, newGroup);
          if (overlappingNames.length > 0) {
            this.rules[2].message = `Some selected students (${overlappingNames.join(', ')}) are already in another group for the same language.`;
            return true; // Warning condition met
          }
          return false; // No overlapping students
        }
      },
    },
  ];

  constructor() {

    this.studentGroupRequest = {
      parentId: '',
      teachingLanguageId: '',
      studentsToAdd: [],
      weekDayTimeSlots: [],
    };
  }


  // Call this method to initialize the current group students
  setCurrentGroupStudents(students: IStudentBasicInfoDto[]) {
    this.currentGroupStudents = students;
  }

  onStudentSelected(
    event: IStudentBasicInfoDto | { studentBasicInfo: IStudentBasicInfoDto; disabled: boolean; selected: boolean } | any,
    preselectedStudents: IStudentBasicInfoDto[] = []
  ) {
    // Initialize the current group students if necessary
    this.setCurrentGroupStudents(preselectedStudents);

    console.log(event);
    const studentId = event.id;

    // Check if the student is selected or deselected
    if (event.selected) {
      this.selectStudent(studentId);
    } else {
      this.deselectStudent(studentId);
    }

    // Log the state for debugging
    console.log('Students to add:', this.studentsToAdd);
    console.log('Students to remove:', this.studentsToRemove);
  }

  private selectStudent(studentId: string) {
    // Check if the student is already in the current group
    const isInGroup = this.currentGroupStudents.some(student => student.id === studentId);

    if (isInGroup) {
      // Student is in the group, ensure they're removed from studentsToAdd
      this.studentsToAdd = this.studentsToAdd.filter(id => id !== studentId);
    } else {
      // Student is not in the group, add to studentsToAdd if not already present
      if (!this.studentsToAdd.includes(studentId)) {
        this.studentsToAdd.push(studentId);
      }
    }

    // Remove from studentsToRemove if present
    this.studentsToRemove = this.studentsToRemove.filter(id => id !== studentId);
  }

  private deselectStudent(studentId: string) {
    // Check if the student is in the current group
    const isInGroup = this.currentGroupStudents.some(student => student.id === studentId);

    if (isInGroup) {
      // Student is in the group, ensure they're added to studentsToRemove
      if (!this.studentsToRemove.includes(studentId)) {
        this.studentsToRemove.push(studentId);
      }
    } else {
      // Student is not in the group, remove from both studentsToAdd and studentsToRemove
      this.studentsToAdd = this.studentsToAdd.filter(id => id !== studentId);
      this.studentsToRemove = this.studentsToRemove.filter(id => id !== studentId);
    }
  }

  /**
 * Finds a group object by its group ID.
 * @param existingGroups - Array of existing groups.
 * @param groupId - The ID of the group to find.
 * @returns The group object if found, otherwise null.
 */
  findGroupById(existingGroups: IStudentGroupDto[], groupId: string): IStudentGroupDto {
    return existingGroups.find(group => group.id === groupId) as IStudentGroupDto; // Assuming group has an 'id' property
  }

  /**
    * Checks if a student ID exists in a given group of students.
    * @param studentId - The ID of the student to check.
    * @param groupStudents - The array of students representing the group.
    * @returns True if the student ID exists in the group; otherwise, false.
    */
  studentExistsInGroup(studentId: string, groupStudents: IStudentBasicInfoDto[]): boolean {
    if (!groupStudents) {
      return false;
    }
    return groupStudents.some(student => student.id === studentId);
  }

  /**
   * Checks if a group with the same language, level, and students already exists.
   */
  groupExists(existingGroups: IStudentGroupDto[], newGroup: any): boolean {
    console.log(existingGroups, newGroup.studentsBasicInfo);
    return existingGroups.some(existingGroup =>
      existingGroup.groupLanguageName === newGroup.groupLanguageName &&
      existingGroup.groupLevel === newGroup.groupLevel &&
      this.hasSameStudents(existingGroup.studentsBasicInfo!, newGroup.studentsBasicInfo)
    );
  }

  /**
   * Helper function to compare two arrays of students by their IDs.
   * Returns true if both arrays contain the same students (order does not matter).
   */
  private hasSameStudents(students1: IStudentBasicInfoDto[], students2: IStudentBasicInfoDto[]): boolean {
    if (students1.length !== students2.length) return false;

    // Get the sorted arrays of student IDs
    const studentIds1 = students1.map(student => student.id).sort();
    const studentIds2 = students2.map(student => student.id).sort();

    // Check if every ID in the first list matches the corresponding ID in the second
    return studentIds1.every((id, index) => id === studentIds2[index]);
  }

  studentsBelongToOtherGroupWithSameLanguage(existingGroups: IStudentGroupDto[], newGroup: IStudentGroupDto | any): string[] {
    const overlappingNames: string[] = [];

    existingGroups.forEach(existingGroup => {
      if (existingGroup.groupLanguageName === newGroup.groupLanguageName) { // Same language
        const overlappingStudents = this.getOverlappingStudentNames(existingGroup.studentsBasicInfo!, newGroup.studentsBasicInfo);
        overlappingNames.push(...overlappingStudents);
      }
    });

    return overlappingNames; // Return array of names of overlapping students
  }

  /**
   * Helper function to get names of overlapping students between two groups.
   */
  private getOverlappingStudentNames(students1: IStudentBasicInfoDto[], students2: IStudentBasicInfoDto[]): string[] {
    const studentIds1 = new Set(students1.map(student => student.id));
    return students2
      .filter(student => studentIds1.has(student.id))
      .map(student => student.firstName); // Assuming students have a firstName property
  }

  /**
   * Maps a StudentBasicInfoDto to a simplified object with only the necessary properties to display in the student selection list.
   * @param student The student to map.
   * @returns A simplified object with the student's id, first and last name, timezone IANA, and timezone display name.
   */

  mapStudentToBasicInfo(student: IStudentBasicInfoDto) {
    return {
      id: student.id,
      firstName: student.firstName,
      lastName: student.lastName,
      timezoneIana: student.timezoneIana,
      timezoneDisplayName: student.timezoneDisplayName
    };
  }
  /**
 * Validates the selected students against all defined rules.
 * Returns an array of rule messages that were violated.
 */
  validateSelectedStudents(existingGroups: IStudentGroupDto[], newGroup: any, isEditMode: boolean = false): StoudentGroupRuleMessage[] {
    const messages: StoudentGroupRuleMessage[] = [];

    const existingStudentIds = new Set(newGroup.studentsBasicInfo.map((student: any) => student.id));

    // Check all rules when not in edit mode
    for (const rule of this.rules) {
      if (rule.validate(existingGroups, newGroup, isEditMode)) {
        messages.push({
          type: rule.type,
          message: rule.message,
        });
      }
      //   if (isEditMode) {
      //     const filteredNewGroup = {
      //         ...newGroup,
      //         studentsBasicInfo: newGroup.studentsBasicInfo.filter((student: any) => !existingStudentIds.has(student.id))
      //     };
      //     if (rule.validate(existingGroups, filteredNewGroup, isEditMode)) {
      //         messages.push({
      //             type: rule.type,
      //             message: rule.message,
      //         });
      //     }
      // } else {
      //     // Original validation for new groups
      //     if (rule.validate(existingGroups, newGroup, isEditMode)) {
      //         messages.push({
      //             type: rule.type,
      //             message: rule.message,
      //         });
      //     }
      // }
    }

    return messages;
  }

  getTeachingLanguageObjectFromName(teachingLanguages: ITeachingLanguageDto[], teachingLanguageName: string) {
    const selectedLanguageObject = teachingLanguages.find(language => language.name === teachingLanguageName);

    return selectedLanguageObject;
  }


  // Sort language levels based on studentToTeachingLanguages
  sortLanguageLevels(languageLevels: { name: string, code: ILanguageLevelsEnum }[], students: IStudentBasicInfoDto[]): any[] {
    const languageLevelCodes = new Set<number>();
    students.forEach(student => {
      student.studentTeachingLanguages!.forEach((lang: any) => languageLevelCodes.add(lang.languageLevel));
    });

    return languageLevels.map(level => ({
      ...level,
      disabled: !languageLevelCodes.has(level.code)
    })).sort((a, b) => {
      const aIncluded = !a.disabled;
      const bIncluded = !b.disabled;
      if (aIncluded && !bIncluded) return -1;
      if (!aIncluded && bIncluded) return 1;
      return 0;
    });
  }

  sortTeachingLanguages(teachingLanguages: any[], students: any[]): any[] {
    const teachingLanguageIds = new Set<string>();
    students.forEach(student => {
      student.studentToTeachingLanguages.forEach((lang: any) => teachingLanguageIds.add(lang.teachingLanguageId));
    });

    return teachingLanguages.map(language => ({
      ...language,
      disabled: !teachingLanguageIds.has(language.id),
      selected: false,
    })).sort((a, b) => {
      const aIncluded = !a.disabled;
      const bIncluded = !b.disabled;
      if (aIncluded && !bIncluded) return -1;
      if (!aIncluded && bIncluded) return 1;
      return 0;
    });
  }


  /**
   * Sorts students by disabled and match properties.
   * A disabled student comes after a non-disabled one.
   * A student with a match comes after a student without a match.
   * @param a The first student to compare.
   * @param b The second student to compare.
   * @returns A negative value if a should come first, a positive value if b should come first, and 0 if the order does not matter.
   */
  sortStudents(a: any, b: any): number {
    if (!a.disabled && b.disabled) return -1;
    if (a.disabled && !b.disabled) return 1;
    if (a.match && !b.match) return -1;
    if (!a.match && b.match) return 1;
    return 0;
  }

  // Method to get the current student group request
  getStudentGroupRequest(): ICreateStudentGroupRequest {
    return { ...this.studentGroupRequest }; // Return a copy to prevent direct modification
  }

  // Method to set parent ID
  addStudentGroupRequestParentId(parentId: string): void {
    this.studentGroupRequest.parentId = parentId;
  }

  addStudentGroupRequestStudents(studentIds: string[]): void {
    studentIds.forEach(studentId => {
      if (!this.studentGroupRequest.studentsToAdd.includes(studentId)) {
        this.studentGroupRequest.studentsToAdd.push(studentId);
      }
    });
  }

  // Method to update any key in the studentGroupRequest
  updateStudentGroupRequest(partialRequest: Partial<ICreateStudentGroupRequest>): void {
    this.studentGroupRequest = {
      ...this.studentGroupRequest,
      ...partialRequest
    };
  }


  // Method to update language details
  updateStudentGroupRequestLanguageDetails(languageDto: IRegisterStudentToTeachingLanguageDto): void {
    // this.studentGroupRequest.registerStudentToTeachingLanguageDto = {
    //   ...this.studentGroupRequest.registerStudentToTeachingLanguageDto,
    //   ...languageDto
    // };
  }

  // Optional: Method to reset the request
  resetStudentGroupRequest(): void {
    this.studentGroupRequest = {
      parentId: '',
      studentsToAdd: [],
      teachingLanguageId: '',
      weekDayTimeSlots: [],
    };
  }

}
