<div class="align-items-center flex justify-content-center w-full ng-star-inserted">
  <div class="border-round flex flex-column align-items-center flex justify-content-center
    sm:flex-row w-full py-3 px-5">
    <div class=" flex align-items-center justify-content-center p-2"><img
      alt="Image" class="mx-auto block w-full w-4rem"
      src="/assets/images/graphic/universe-planet-right-center-eyes.webp"></div>
    <div class=" flex flex-column align-items-start">
      <div class="text-900 font-medium mb-2 text-xl">
        {{ this.generalService.getGroupDetails(studentGroupItem()) }}
      </div>
      <app-student-group-row-display [studentGroupItem]="studentGroupItem()"></app-student-group-row-display>
    </div>
  </div>
</div>
