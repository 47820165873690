import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Injector,
  Input,
  Output,
  computed,
  inject,
  input,
  signal
} from '@angular/core';
import {toObservable} from '@angular/core/rxjs-interop';
import {FormsModule} from '@angular/forms';
import {IStudentBasicInfoDto} from '@GeneratedTsFiles/index';
import {CheckboxModule} from 'primeng/checkbox';
import {untilDestroyed} from 'src/app/core/helpers/until-destroyed';
import {GeneralService} from 'src/app/core/services/general.service';

@Component({
  selector: 'app-image-box-group-item',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    CheckboxModule,
  ],
  templateUrl: './image-box-group-item.component.html',
  styleUrl: './image-box-group-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageBoxGroupItemComponent {

  generalService = inject(GeneralService);

  @Input() selectionMode: 'single' | 'multiple' = 'single';
  @Input() nameProperty: string = 'name'; // Default name property
  @Input() imageProperty: string = 'image'; // Default image property
  @Input() items: any[] = []; // Items array
  @Input() item: any = {}; // Items array
  @Input() createNewText!: string;
  @Input() createNewImage!: string;
  @Input() selectable = true;
  @Input() removeable = true;
  @Input() languagesMode = false;
  @Input() studentGroupMode = false;
  @Input() numVisible = 4;
  @Input() selectedItem: any = null; // Currently selected item

  @Input() isSelected = false;
  @Input() isDisabled = false;
  textForNameProperty = input('');
  baseProperty = input(''); // Default image property name

  @Output() itemClicked: EventEmitter<any> = new EventEmitter();
  @Output() newItemClicked: EventEmitter<any> = new EventEmitter();
  @Output() pageChanged: EventEmitter<number> = new EventEmitter();


  // Maintain a Map of item selection statuses
  selectedItemsMap = new Map<any, boolean>();

  selectedItems = input([] as any[]);
  disabledItems = input([] as any[]);

  private untilDestroyed = untilDestroyed();
  private injector = inject(Injector);
  carouselIndex: number = 0; // Current carousel index
  responsiveOptions: { breakpoint: string; numVisible: number; numScroll: number; }[] = [];


  ngOnInit() {

    if (this.createNewText && this.createNewImage) {
      this.createNewItem();
    }

    if (this.selectionMode === 'multiple') {
      this.initializeSelectedItems();
    }

  }

  ngOnDestroy() {
    this.untilDestroyed();
  }

  @HostListener('window:resize')
  onResize() {
  }


  private initializeSelectedItems() {
    if (!this.items) {
      return;
    }
    this.items.forEach(item => {
      const isSelected = this.selectedItems().some(selected => selected.id === item.studentBasicInfo.id);
    });
  }

  private createNewItem() {
    const newItem = {
      [this.nameProperty]: this.createNewText,
      [this.imageProperty]: this.createNewImage,
      selected: false,
      new: true
    };
    this.items.unshift(newItem);
  }

  selectItem(item: any) {
    this.toggleSelection(item);
    this.selectedItem = item;

    // Emit the item click event
    this.itemClicked.emit(item);
  }

  toggleSelection(item: any) {
    if (this.isDisabled) {
      return;
    }
    if (this.selectionMode === 'single') {
      this.selectSingle(item);
    } else {
      this.toggleMultiple(item);
    }
  }

  private selectSingle(item: any) {
    this.selectedItemsMap.clear(); // Clear previous selections
    this.selectedItemsMap.set(item, true); // Select the current item
  }

  private toggleMultiple(item: any) {
    this.isSelected = (!this.isSelected);
    item.selected = this.isSelected;
    console.log(item);
    this.itemClicked.emit(item);
  }

  prepareNameProperty(item: any) {
    if (this.studentGroupMode) {
      return this.generalService.getGroupDetails(item);
    }
    if (this.baseProperty()) {
      const baseProperty = this.baseProperty() as string;
      return item[baseProperty][this.nameProperty] + this.textForNameProperty();
    } else {
      return item[this.nameProperty] + this.textForNameProperty();
    }
  }

}
