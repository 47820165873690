<div class="col-12">
{{studentGroupService.currentGroupStudents | json}}
  @if (!availabilityDataLoaded()) {
  <span class="inline-loader">
    <span class="loader-box">

    </span><span class="loader-box"></span>
    <span class="loader-box"></span>
  </span>
  }
  @if (availabilityData() && availabilityDataLoaded()) {

  @if (!areAllCommonTimeSlotsEmptyBool()) {

  <div
    class="flex flex-column md:flex-row md:align-items-center md:justify-content-between p-2 border-1 mb-3 bg-green-50 border-green-500"
    style="border-radius: 10px;">
    <div class="flex align-items-center">
      <i class="pi pi-check-circle text-green-700 mr-2" style="font-size: 1.5em;"></i>
      <div>
        <!-- <div><span class="text-green-700 font-bold">Common Timeslots</span></div> -->
        <p class="text-green-700 text-sm my-0">
          We found some common timeslots for your students. Please review them carefully.
        </p>
      </div>
    </div>
  </div>
  }

  <app-availability-picker-days [availabilityData]="availabilityData()"
    (timeSlotsChange)="onTimeSlotsChange($event)" (availabilityFormValid)="onAvailabilityDaysFormValid($event)"></app-availability-picker-days>
  }

  @if (this.editGroupState() !== GroupDialogState.EditAvailability) {


  <form [formGroup]="availabilityForm" #form="ngForm">
    <!-- <app-student-availability-checkboxes [times]="times" [compact]="false" [(selectedFlags)]="selectedFlags"
        (selectedFlagsChange)="onSelectedFlagsChange($event)">
    </app-student-availability-checkboxes> -->

    <!-- <h3 class="align-items-center line-height-1 text-sm font-semibold mb-1 mt-3 primary-purple-color flex">
        Specific Availability
    </h3>
    <textarea class="w-full more-textarea h-7rem" rows="7" cols="35" [autoResize]="true" pInputTextarea
        formControlName="availabilitySupportedText"
        placeholder="Please specify any specific dates/times you would be available."></textarea> -->

    <h3 class="align-items-center line-height-1 text-sm font-semibold mb-1 mt-3 primary-purple-color flex">
      Students Level <span class="text-red-600">*</span>
    </h3>
    <p-dropdown [filter]="true" [options]="levels" [virtualScrollItemSize]="28" [virtualScroll]="true" appendTo="body"
      dataKey="value" formControlName="studentLevel" optionValue="value" placeholder="Level"
      styleClass="w-full full-width mb-3">
    </p-dropdown>
    <h3 class="align-items-center line-height-1 text-sm font-semibold mb-1 mt-3 primary-purple-color flex">
      More Details
    </h3>
    <textarea [autoResize]="true" class="w-full more-textarea h-7rem mb-3" cols="35" formControlName="moreDetails"
      pInputTextarea
      placeholder="Tell us more about the students, their current level, schedule and goals they want to achieve by taking this course."
      rows="7"></textarea>

  </form>

  <div *ngIf="invalidFields.length > 0 && form.submitted" class="invalid-fields-message">

    <p>The following fields are invalid:</p>

    <ul class="mt-1 list-none p-0 mx-0">
      <li *ngFor="let field of invalidFields" class="flex align-items-center py-1">
        <span class="border-round bg-red-500 mr-3 flex-shrink-0" style="width: 0.725rem; height: 0.725rem;"></span>
        <span class="text-sm font-medium text-90">{{ field }}</span>

      </li>
    </ul>
    <hr>
  </div>
}

</div>
