import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  Inject,
  inject,
  Injector,
  type OnInit,
  signal
} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ButtonModule} from 'primeng/button';
import {DropdownModule} from 'primeng/dropdown';
import {ImageBoxGroupSelectComponent} from '../../image-box-group-select/image-box-group-select.component';
import {CustomDialogPopupComponent} from '../../custom-dialog-popup/custom-dialog-popup.component';
import {
  FormFieldValidationMessageComponent
} from '../../prime/form-field-validation-message/form-field-validation-message.component';
import {ToastService} from 'src/app/core/services/toast.service';
import {ToastMessages} from 'src/app/shared/models/toast-messages';
import {EmitEvent, EventBusService, Events} from 'src/app/core/services/event-bus.service';
import {Subscription} from 'rxjs';
import {DataApiStateService} from 'src/app/core/services/data-api-state.service';
import {GeneralService} from 'src/app/core/services/general.service';
import {UserService} from 'src/app/core/services/user.service';
import {AuthService} from 'src/app/core/services/auth.service';
import {untilDestroyed} from 'src/app/core/helpers/until-destroyed';
import {ApiService} from 'src/app/core/services/api.service';
import {StoudentGroupRuleMessage, StudentGroupService} from 'src/app/core/services/student-group.service';
import {Severity} from 'src/app/core/models/severity';
import {AccordionModule} from 'primeng/accordion';
import {
  ImageBoxGroupItemComponent
} from '../../image-box-group-select/image-box-group-item/image-box-group-item.component';
import {CarouselModule} from 'primeng/carousel';
import {CarouselService} from 'src/app/core/services/carousel.service';
import {
  StudentGroupSelectionSuggestionTextStepComponent
} from './student-group-selection-suggestion-text-step/student-group-selection-suggestion-text-step.component';
import {
  StudentAvailabilityCheckboxesComponent
} from '../../student-availability-checkboxes/student-availability-checkboxes.component';
import {
  StudentGroupSelectionMembersStepComponent
} from './student-group-selection-members-step/student-group-selection-members-step.component';
import {
  IAvailability,
  IEditAvailabilityRequest,
  IGetAvailabilityResponse,
  IGetStudentGroupResponse,
  IStudentBasicInfoDto,
  IStudentGroupDto,
  ITeachingLanguageDto,
  StudentGroupRoutes
} from '@GeneratedTsFiles/index';
import {toObservable} from '@angular/core/rxjs-interop';
import {
  StudentGroupSelectionAvailabilityStepComponent
} from './student-group-selection-availability-step/student-group-selection-availability-step.component';
import {
  StudentGroupSelectionSuccessActionStepComponent
} from './student-group-selection-success-action-step/student-group-selection-success-action-step.component';


export enum GroupDialogState {
  None = -1,
  CreateGroupSuggestionStep = 0,
  CreateGroup = 1,
  CreateGroupAvailability = 2,
  DeleteGroup = 3,
  EditAvailability = 4,
  EditMembers = 5,
  AfterCreateSuccess = 6,
  AfterEditSuccess = 7,
}

@Component({
  selector: 'app-student-group-selection-dialog',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
    ButtonModule,
    AccordionModule,
    CarouselModule,
    ImageBoxGroupSelectComponent,
    CustomDialogPopupComponent,
    FormFieldValidationMessageComponent,
    ImageBoxGroupItemComponent,
    StudentGroupSelectionSuggestionTextStepComponent,
    StudentAvailabilityCheckboxesComponent,
    StudentGroupSelectionAvailabilityStepComponent,
    StudentGroupSelectionMembersStepComponent,
    StudentGroupSelectionSuccessActionStepComponent,
  ],
  templateUrl: './student-group-selection-dialog.component.html',
  styleUrl: './student-group-selection-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    CarouselService,
    StudentGroupService,
  ]
})
export class StudentGroupSelectionDialogComponent implements OnInit {
  // Constants
  private readonly DIALOG_DEFAULT_STYLE = {width: '650px'};
  private readonly DIALOG_DEFAULT_CLASS = 'p-dialog p-dialog-md purple-dialog-header flex justify-content-center';

  // Dialog state signals
  readonly EditGroupState = GroupDialogState;
  dialogVisible = signal(true);
  dialogStyleClass = signal(this.DIALOG_DEFAULT_CLASS);
  dialogStyle = signal(this.DIALOG_DEFAULT_STYLE);
  dialogHeader = signal('Create a Student Group');

  // Group state signals
  editMode = signal(false);
  step = signal(1);
  studentGroupItem = signal({} as IStudentGroupDto);
  editMembersMode = signal(false);
  editGroupState = signal(this.EditGroupState.CreateGroupSuggestionStep);
  canEditGroup = signal(false);
  deleteGroupContent = signal(false);
  newGroupMemberContent = signal(false);

  // Selection state signals
  selectedStudents = signal([] as any[]);
  selectedTeachingLanguage$ = signal<ITeachingLanguageDto | null>(null);
  selectedLanguageLevel$ = signal<any | null>(null);
  resetSelectionSignal = signal(false);
  ruleMessageDisplay = signal([] as StoudentGroupRuleMessage[]);
  availabilityStepValidity = signal(false);
  availabilityDetailsFormValidity = signal(false);

  // Injected services
  readonly services = {
    general: inject(GeneralService),
    auth: inject(AuthService),
    user: inject(UserService),
    api: inject(ApiService),
    studentGroup: inject(StudentGroupService),
    dataState: inject(DataApiStateService),
    toast: inject(ToastService),
    eventBus: inject(EventBusService),
    carousel: inject(CarouselService)
  };

  // Computed signals
  dialogStyle$ = computed(() => this.dialogStyle());
  user = computed(() => this.#userToSignal());
  dialogHeader$ = computed(() => this.computeDialogHeader());
  canGoBack$ = computed(() => this.computeCanGoBack());
  studentGroups$ = computed(() => this.services.dataState.parentStudentsGroups.state());
  createStudentGroup$ = computed(() => this.services.dataState.createStudentGroup.state() || []);

  // Other properties
  private readonly destroy: DestroyRef = inject(DestroyRef);
  private subscriptions: Subscription[] = [];
  #userToSignal = this.services.auth.userDecodedJWTData$;
  readonly Severity = Severity;
  private readonly injector = inject(Injector);
  private readonly untilDestroyed = untilDestroyed();

  parameters: any;
  times: { time: string, flag: any, checked: boolean, day: string }[] = [];
  selectedFlags: number | undefined | any = undefined;
  preselectedStudents = [] as IStudentBasicInfoDto[];
  studentGroups = [] as IStudentGroupDto[];

  constructor(@Inject('dialogParameters') parameters: any) {
    this.parameters = parameters;
  }

  ngOnInit(): void {
    this.initializeComponent();
    this.setupSubscriptions();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.services.general.setErrorDataSignal('');
  }

  // Computed getters
  get isEditModeAndDeleteGroupMode(): boolean {
    return this.editMode() && this.editGroupState() === this.EditGroupState.DeleteGroup;
  }

  get isEditModeOrAfterEdit(): boolean {
    return (this.editMode() && this.editGroupState() === this.EditGroupState.None) ||
      this.editGroupState() === this.EditGroupState.AfterEditSuccess;
  }

  get isEditMembers(): boolean {
    return this.editMode() && this.editGroupState() === this.EditGroupState.EditMembers;
  }

  get isEditAvailability(): boolean {
    return this.editMode() && this.editGroupState() === this.EditGroupState.EditAvailability;
  }

  // Event handlers
  onGroupStateChanged(state: GroupDialogState): void {
    this.editGroupState.set(state);
    this.editMode.set(state !== GroupDialogState.CreateGroup);
  }

  onGroupItemChanged(item: IStudentGroupDto): void {
    this.studentGroupItem.set(item);
  }

  onSuggestionTextButtonSelected(event: any): void {
    this.step.set(2);
    this.editGroupState.set(this.EditGroupState.CreateGroup);
  }

  onMembersStepSubmitted(event: any): void {
    this.editGroupState.set(this.EditGroupState.CreateGroupAvailability);
  }

  onAvailabilityStepSubmitted(): void {
    if (this.isEditAvailability) {
      this.saveGroupAvailability(this.studentGroupItem().availabilityId!);
    } else {
      this.createNewGroup();
    }
  }

  onAvailabilityStepValidityChanged(event: boolean): void {
    this.availabilityStepValidity.set(event);
  }

  onAvailabilityStepValuesChanged(event: any): void {
    console.log(this.services.studentGroup.getStudentGroupRequest());
  }

  membersActionCompleted(event: any): void {
    this.editGroupState.set(this.EditGroupState.None);
  }

  deleteGroupStepSelected(): void {
    this.deleteGroupContent.set(true);
    this.editGroupState.set(this.EditGroupState.DeleteGroup);
  }

  deleteGroupStepActionSelected(action: string): void {
    if (action === 'yes') {
      this.deleteGroupContent.set(true);
      this.deleteStudentGroup();
    } else if (action === 'no') {
      this.deleteGroupContent.set(false);
    }
  }

  backToViewGroup(): void {
    if (this.isEditModeAndDeleteGroupMode) {
      this.resetEditGroupState();
    } else if (this.isEditModeOrAfterEdit) {
      this.editGroupState.set(this.EditGroupState.AfterEditSuccess);
    } else if (this.isEditMembers || this.isEditAvailability) {
      this.resetEditGroupState();
    } else {
      this.editGroupState.set(this.EditGroupState.CreateGroup);
      this.resetContentFlags();
    }
  }

  onAvailabilityDetailsFormValid($event: boolean) {
    this.availabilityDetailsFormValidity.set($event);
  }

  // Private methods
  private initializeComponent(): void {
    this.setInitialState();
    this.initializeEditModeIfNeeded();
    this.initEvents();
  }

  private setInitialState(): void {
    this.editMode.set(this.parameters.editMode);
    this.studentGroupItem.set(this.parameters.studentGroupItem);
    this.dialogStyleClass.set(this.DIALOG_DEFAULT_CLASS);
    this.dialogStyle.set(this.DIALOG_DEFAULT_STYLE);

    if (this.studentGroupItem()) {
      this.preselectedStudents = this.studentGroupItem().studentsBasicInfo!;
    }

    if (this.isEditModeOrAfterEdit) {
      this.getStudentGroup();
    }
  }

  private initializeEditModeIfNeeded(): void {
    if (this.editMode()) {
      this.editGroupState.set(this.EditGroupState.None);
      this.dialogHeader.set('Edit Student Group');
    }
  }

  private initEvents(): void {
    this.services.eventBus.emit(new EmitEvent(Events.StateLoadTeachingLanguages, undefined));
    this.subscribeToEvents();
  }

  private resetEditGroupState(): void {
    this.editGroupState.set(this.EditGroupState.None);
    this.resetContentFlags();
  }

  private resetContentFlags(): void {
    this.editMembersMode.set(false);
    this.deleteGroupContent.set(false);
    this.newGroupMemberContent.set(false);
  }

  private computeDialogHeader(): string {

    const headerMap = {
      [this.EditGroupState.None]: 'Edit Group',
      [this.EditGroupState.CreateGroup]: 'Select your Students',
      [this.EditGroupState.DeleteGroup]: 'Delete Group',
      [this.EditGroupState.EditAvailability]: 'Edit Group Availability',
      [this.EditGroupState.EditMembers]: 'Edit Group Members',
      [this.EditGroupState.AfterCreateSuccess]: 'Group successfully created',
      [this.EditGroupState.AfterEditSuccess]: 'Edit Student Group',
      [this.EditGroupState.CreateGroupAvailability]: 'Set Group Availability',
      [this.EditGroupState.CreateGroupSuggestionStep]: 'Create Your Group Class in 3 Easy Steps!',

    };

    return headerMap[this.editGroupState()] as string || 'Create a Student Group';
  }

  private computeCanGoBack(): boolean {
    return this.newGroupMemberContent() ||
      this.editMembersMode() ||
      this.editMode() ||
      (this.editGroupState() === this.EditGroupState.CreateGroupAvailability &&
        this.editGroupState() !== this.EditGroupState.None &&
        this.editGroupState() !== this.EditGroupState.AfterEditSuccess);
  }

  // API calls
  private createNewGroup(): void {
    const request = this.services.studentGroup.getStudentGroupRequest();
    this.services.dataState
      .handleApiCall(
        this.services.user.createStudentGroup(request),
        this.services.dataState.createStudentGroup.setState
      )
      .pipe(this.untilDestroyed())
      .subscribe({
        next: this.handleCreateGroupSuccess
      });
  }

  private deleteStudentGroup(): void {
    this.services.api.getApiData<IGetStudentGroupResponse>(
      {url: StudentGroupRoutes.deleteStudentGroup, method: 'DELETE'},
      {
        parentId: this.user()?.id,
        groupId: this.studentGroupItem().id
      }
    ).pipe(this.untilDestroyed()).subscribe({
      next: this.handleDeleteGroupSuccess,
      error: this.handleApiError
    });
  }

  private getStudentGroup(): void {
    this.services.api.getApiData<IGetStudentGroupResponse>(
      {url: StudentGroupRoutes.getStudentGroup, method: 'GET'},
      {GroupId: this.studentGroupItem().id}
    ).pipe(this.untilDestroyed()).subscribe({
      next: this.handleGetGroupSuccess,
      error: this.handleApiError
    });
  }

  private saveGroupAvailability(availabilityId: string): void {
    if (!availabilityId) return;

    const editAvailabilityRequest = this.createEditRequest(availabilityId);

    this.services.api.getApiData<IGetAvailabilityResponse>(
      {url: IAvailability.patchEditAvailability, method: 'PATCH'},
      editAvailabilityRequest
    ).pipe(this.untilDestroyed()).subscribe({
      next: this.handleSaveAvailabilitySuccess,
      error: this.handleApiError
    });
  }

  // Success handlers
  private handleCreateGroupSuccess = (): void => {
    this.editGroupState.set(this.EditGroupState.AfterCreateSuccess);
    this.services.toast.show(ToastMessages.StudentsGroupAdd.success);
    this.services.eventBus.emit(new EmitEvent(Events.StudentGroupAdded, {name: 'Group 1'}));
  }

  private handleDeleteGroupSuccess = (response: IGetStudentGroupResponse): void => {
    this.services.eventBus.emit(new EmitEvent(Events.StateLoadParentStudentsGroups, undefined));
    this.services.toast.show(ToastMessages.StudentsGroupDelete.success);
    this.dialogVisible.set(false);
  }

  private handleGetGroupSuccess = (response: IGetStudentGroupResponse): void => {
    this.studentGroupItem.set(response.studentGroup);
  }

  private handleSaveAvailabilitySuccess = (response: IGetAvailabilityResponse): void => {
    if (response?.availability) {
      this.services.toast.show(ToastMessages.StudensGroupEditAvailability.success);
      this.editGroupState.set(this.EditGroupState.None);
    } else {
      this.services.toast.show(ToastMessages.StudensGroupEditAvailability.error);
    }
  }

  // Error handlers
  private handleApiError = (error: any): void => {
    console.error('API Error:', error);
    if (error.statusCode === 400) {
      // Handle 400 error specifically if needed
    }
    this.services.general.handleRouteError(error, StudentGroupRoutes.postCreateStudentGroup);
  }

  // Subscription setup
  private setupSubscriptions(): void {
    this.subscribeToStudentGroups();
  }

  private subscribeToEvents(): void {
    const subscriptions = [
      this.services.eventBus.on(Events.StudentGroupAdded, this.handleStudentGroupAdded),
      this.services.eventBus.on(Events.StudentGroupRemoved, this.handleStudentGroupRemoved),
      this.services.eventBus.on(Events.StudentGroupNewMemberAdded, this.handleStudentGroupNewMemberAdded)
    ];
    this.subscriptions.push(...subscriptions);
  }

  private subscribeToStudentGroups(): void {
    toObservable(this.studentGroups$, {injector: this.injector})
      .pipe(this.untilDestroyed())
      .subscribe({
        next: (stateData: any) => {
          if (stateData.data?.studentGroups && this.editMode()) {
            // Handle student groups data if needed
          }
        }
      });
      toObservable(this.editGroupState, {injector: this.injector})
        .pipe(this.untilDestroyed())
        .subscribe({
          next: (state: any) => {
            if (state === this.EditGroupState.EditMembers) {
              this.getStudentGroup();
              // Handle student groups data if needed
            }
          }
        });
  }

  // Event handlers
  private handleStudentGroupAdded = (payload: any): void => {
    console.log(`Customer Selected: ${payload.name}`);
  }

  private handleStudentGroupRemoved = (payload: any): void => {
    console.log(Events.StudentGroupRemoved, `: ${payload}`);
  }

  private handleStudentGroupNewMemberAdded = (payload: any): void => {
    console.log(Events.StudentGroupNewMemberAdded, `: ${payload}`);
  }

  // Helper methods
  private createEditRequest(availabilityId: string): IEditAvailabilityRequest {
    return {
      availability: {
        availabilityId,
        timeZoneIana: this.user()?.timeZoneIana!,
        timeZoneDisplayName: this.user()?.timeZoneDisplayName!,
        timeZoneId: this.user()?.timeZoneId!,
        weekDayTimeSlots: this.services.studentGroup.getStudentGroupRequest().weekDayTimeSlots
      }
    };
  }
}
