<div class="flex flex-column align-items-center justify-content-center">

  <div class="flex flex-column align-items-center justify-content-center mx-2 mb-3 text-md primary-purple-color">
    <div class="grid grid-nogutter align-items-center">
      <div class="col-12 flex flex-column align-items-center justify-content-center sm:flex-row gap-2 mt-2">

        <img class="w-3rem sm:w-5rem" src="/assets/images/graphic/universe-planet-right-stars-eyes.webp"/>
        <div class="flex flex-column align-items-start justify-content-center">
          <h3 class="m-0">Create Your
            Group Class in 3 Easy Steps!</h3>
          <p class="m-0">Proceed to next step to create your group class</p>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="font-bold text-900 text-lg my-3 text-center"><i class="pi pi-user text-lg mr-2"></i>Create Your
      Group Class in 3 Easy Steps!</div> -->
  <div
    class="align-items-center flex flex-column justify-content-center mx-2 md:mx-6 border-round mb-7 sm:mb-5 border-2 border-indigo-500 bg-indigo-50 ng-star-inserted">
    <ul class="list-none p-0 m-0 py-2 surface-0  border-round">
      <li class="p-1 sm:p-3 flex">
        <div class="hidden sm:flex flex-column align-items-center" style="width: 2rem;"><span
          class="surface-0 text-blue-500 border-blue-500 border-2 border-circle flex align-items-center justify-content-center border-circle"
          style="min-width: 2rem; min-height: 2rem;">1</span>
          <!-- <div class="h-full bg-blue-500" style="width: 2px; min-height: 4rem;"></div> -->
        </div>
        <div class="ml-3">
          <div class="font-medium text-900 mb-2">Discover the Benefits of Group Classes</div>
          <span
            class="line-height-3 text-700">

                        Since your students are learning the same language, they may benefit from group classes
                        where
                        they attend lessons simultaneously with the same teacher. Group lessons come at a
                        reduced price!
                        Check out our pricing here (link for pricing).
                    </span>
        </div>
      </li>
      <li class="p-1 sm:p-3 flex">
        <div class="hidden sm:flex flex-column align-items-center" style="width: 2rem;"><span
          class="surface-0 text-blue-500 border-blue-500 border-2 border-circle flex align-items-center justify-content-center border-circle"
          style="min-width: 2rem; min-height: 2rem;"> 2 </span>
          <!-- <div class="h-full bg-blue-500" style="width: 2px; min-height: 4rem;"></div> -->
        </div>
        <div class="ml-3">
          <div class="font-medium text-900 mb-2">Tips for a Successful Group Class</div>
          <span
            class="line-height-3 text-700">

                        <ul class="list-none p-0 m-0">
                            <li class="surface-border">
                                <div class="mb-1"> You can only include students at the same language level.
                                </div>
                            </li>
                            <li class="surface-border">
                                <div class=" mb-1">For students under 10, ensure there is no more than a 3-year
                                    age
                                    difference.</div>
                            </li>
                            <li class="surface-border">
                                <div class="">Students should have the same availability during the week.</div>
                            </li>
                        </ul>

                    </span>
        </div>
      </li>
      <li class="p-1 sm:p-3 flex">
        <div class="hidden sm:flex flex-column align-items-center" style="width: 2rem;"><span
          class="surface-0 text-blue-500 border-blue-500 border-2 border-circle flex align-items-center justify-content-center border-circle"
          style="min-width: 2rem; min-height: 2rem;"> 3 </span>
          <!-- <div class="h-full bg-blue-500" style="width: 2px; min-height: 4rem;"></div> -->
        </div>
        <div class="ml-3">
          <div class="font-medium text-900 mb-2">Select Your Students</div>
          <span
            class="line-height-3 text-700">

                        Based on our recommendations, please select the students you would like to include in
                        your group
                        class.


                    </span>
        </div>
      </li>

    </ul>

    <button (click)="onItemClicked()" class="proceed-btn p-element p-ripple p-button-rounded p-button bg-indigo-600 p-component my-2" icon="pi pi-arrow-right" iconpos="right" label="Learn More" pRipple
            pbutton=""><span
      aria-hidden="true" class="p-button-icon p-button-icon-right pi pi-arrow-right"></span><span
      class="p-button-label">Proceed</span><span class="p-ink"></span></button>

  </div>


</div>
